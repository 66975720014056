import React from "react";

const Logo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    enableBackground="new 0 0 500 500"
    width="80px"
    height="80px"
    // xml:space="preserve"
  >
    <path
      fill="#A55FD9"
      opacity="1.000000"
      stroke="none"
      d="
M119.000000,501.000000 
	C114.310745,501.000000 109.621490,501.000000 104.802399,500.686127 
	C101.101234,499.286407 97.529915,498.200562 94.047165,496.698486 
	C94.423553,493.982056 94.962753,491.681946 94.963326,489.381744 
	C95.002892,330.163422 95.003708,170.945084 94.956078,11.726764 
	C94.955391,9.447188 94.303520,7.167808 94.158371,4.551275 
	C96.987213,3.805028 99.640022,3.511042 102.221672,2.910947 
	C102.924538,2.747568 103.413086,1.662214 104.000000,0.999998 
	C108.689255,1.000000 113.378510,1.000000 118.533882,1.467854 
	C119.000000,168.290466 119.000000,334.645233 119.000000,501.000000 
z"
    />
    <path
      fill="#E58375"
      opacity="1.000000"
      stroke="none"
      d="
M398.000000,501.000000 
	C392.311951,501.000000 386.623932,501.000000 380.467957,500.531342 
	C380.000000,333.708466 380.000000,167.354233 380.000000,1.000000 
	C385.688049,1.000000 391.376068,1.000000 397.194153,1.276358 
	C398.549713,2.090617 399.775208,2.628519 400.908722,3.563449 
	C400.544464,6.927134 400.034760,9.893732 400.034027,12.860455 
	C399.995544,170.720215 399.992401,328.579987 400.041931,486.439758 
	C400.043213,490.570953 400.667358,494.701965 400.580902,498.906738 
	C399.440521,499.653625 398.720276,500.326813 398.000000,501.000000 
z"
    />
    <path
      fill="#AA62D1"
      opacity="1.000000"
      stroke="none"
      d="
M119.467560,501.000000 
	C119.000000,334.645233 119.000000,168.290466 119.000000,1.467854 
	C125.021629,1.000000 131.043259,1.000000 137.532440,1.465013 
	C138.000000,168.286682 138.000000,334.643341 138.000000,501.000000 
	C131.978363,501.000000 125.956741,501.000000 119.467560,501.000000 
z"
    />
    <path
      fill="#8F53FB"
      opacity="1.000000"
      stroke="none"
      d="
M0.999993,399.000000 
	C1.000000,300.645782 1.000000,202.291550 1.313465,103.805183 
	C2.071544,103.015694 2.795820,102.406944 2.919763,101.693855 
	C5.817784,85.020218 12.282725,69.837967 22.127361,56.296783 
	C22.576977,58.439796 22.973061,60.238636 22.973475,62.037567 
	C23.002415,187.784912 23.005190,313.532257 22.956491,439.279572 
	C22.955608,441.560272 22.308493,443.840729 21.646427,445.958191 
	C18.899345,441.606354 15.845386,437.652313 14.162040,433.182159 
	C10.090467,422.369904 6.679842,411.309479 2.910555,400.380890 
	C2.700960,399.773224 1.656076,399.453674 0.999993,399.000000 
z"
    />
    <path
      fill="#E1817B"
      opacity="1.000000"
      stroke="none"
      d="
M379.532043,1.000000 
	C380.000000,167.354233 380.000000,333.708466 380.000000,500.531342 
	C375.975464,501.000000 371.950897,501.000000 367.463196,500.531342 
	C366.999969,463.259491 367.021088,426.456268 366.948181,389.653198 
	C366.943756,387.418243 366.192871,385.184753 365.892395,382.598206 
	C376.080353,361.949677 377.094025,340.468719 373.918518,318.573273 
	C372.290741,307.349640 367.198273,297.396240 361.193359,287.671997 
	C361.611969,286.020966 361.971832,284.719788 361.972992,283.418274 
	C362.003693,248.842682 362.012329,214.267014 361.948914,179.691513 
	C361.946014,178.116547 361.236572,176.542908 361.011047,174.665634 
	C362.880768,171.369873 366.447571,168.541367 361.997070,164.532974 
	C361.998688,109.709473 361.999329,55.354736 362.000000,1.000000 
	C367.688049,1.000000 373.376068,1.000000 379.532043,1.000000 
z"
    />
    <path
      fill="#AD64CC"
      opacity="1.000000"
      stroke="none"
      d="
M138.449890,501.000000 
	C138.000000,334.643341 138.000000,168.286682 138.000000,1.465013 
	C142.690811,1.000000 147.381607,1.000000 152.536209,1.468657 
	C153.000000,53.712975 152.984924,105.488655 153.044220,157.264252 
	C153.047211,159.876434 153.692795,162.487885 154.029877,165.479736 
	C153.774689,166.641052 153.544586,167.427704 153.280624,168.202820 
	C149.045425,180.639099 146.350998,193.277573 148.969315,206.453278 
	C150.530045,214.307144 152.426193,222.094345 153.923676,230.254578 
	C153.450745,232.224487 153.031281,233.851089 153.029358,235.478180 
	C152.990997,267.920380 152.983688,300.362640 153.048050,332.804749 
	C153.051514,334.553284 153.738113,336.300446 154.016937,338.381561 
	C149.327713,344.451508 144.728287,350.188171 139.822342,356.307098 
	C142.914749,360.848572 146.018234,365.406311 148.762634,370.131012 
	C147.935715,372.469238 147.064072,374.639740 147.059830,376.811920 
	C146.978836,418.207825 147.000015,459.603943 147.000000,501.000000 
	C144.299927,501.000000 141.599854,501.000000 138.449890,501.000000 
z"
    />
    <path
      fill="#FD9050"
      opacity="1.000000"
      stroke="none"
      d="
M501.000000,104.000000 
	C501.000000,201.687561 501.000000,299.375122 500.682129,397.191528 
	C499.938446,397.816620 499.212952,398.267700 499.130493,398.815613 
	C496.903595,413.610382 491.923584,427.370789 483.645630,439.617798 
	C483.304871,437.607452 483.019348,435.960999 483.019073,434.314453 
	C482.998352,312.442688 482.995117,190.570938 483.046417,68.699203 
	C483.047211,66.783897 483.740295,64.868889 484.421875,63.113262 
	C491.789917,75.561539 496.965088,88.529778 499.107819,102.623352 
	C499.190216,103.165588 500.344971,103.544807 501.000000,104.000000 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M501.000000,103.531342 
	C500.344971,103.544807 499.190216,103.165588 499.107819,102.623352 
	C496.965088,88.529778 491.789917,75.561539 484.369781,62.751083 
	C477.707123,54.137005 471.408112,46.044624 464.974213,37.642399 
	C457.908997,31.852470 450.978668,26.372381 443.906982,20.574001 
	C436.859802,16.801363 429.953979,13.347018 422.871613,9.569033 
	C415.463654,7.219067 408.232178,5.192742 401.000732,3.166419 
	C399.775208,2.628519 398.549713,2.090617 397.662109,1.276358 
	C432.260284,1.000000 466.520538,1.000000 501.000000,1.000000 
	C501.000000,35.020580 501.000000,69.041634 501.000000,103.531342 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M483.834106,439.981689 
	C491.923584,427.370789 496.903595,413.610382 499.130493,398.815613 
	C499.212952,398.267700 499.938446,397.816620 500.682129,397.660217 
	C501.000000,432.260284 501.000000,466.520538 501.000000,501.000000 
	C466.979431,501.000000 432.958374,501.000000 398.468689,501.000000 
	C398.720276,500.326813 399.440521,499.653625 400.981262,498.966797 
	C408.534821,496.912994 415.267883,494.872803 422.359955,492.789703 
	C424.023651,492.160858 425.380585,491.668365 426.624115,490.973022 
	C432.447998,487.716522 438.240692,484.404205 444.400665,481.057190 
	C451.539459,475.352081 458.322144,469.701569 465.411560,463.919861 
	C471.756866,455.853027 477.795471,447.917358 483.834106,439.981689 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M0.999993,399.468689 
	C1.656076,399.453674 2.700960,399.773224 2.910555,400.380890 
	C6.679842,411.309479 10.090467,422.369904 14.162040,433.182159 
	C15.845386,437.652313 18.899345,441.606354 21.781263,446.273529 
	C29.118589,454.516968 36.004601,462.282013 43.032291,470.365112 
	C44.803535,471.979889 46.337109,473.425873 48.079720,474.546875 
	C54.648190,478.772217 61.284649,482.891846 68.063690,487.372131 
	C76.807343,490.833740 85.382965,493.974243 93.958588,497.114746 
	C97.529915,498.200562 101.101234,499.286407 104.336273,500.686127 
	C69.740082,501.000000 35.480167,501.000000 1.000000,501.000000 
	C1.000000,467.312744 1.000000,433.625031 0.999993,399.468689 
z"
    />
    <path
      fill="#000000"
      opacity="1.000000"
      stroke="none"
      d="
M21.889164,55.952648 
	C12.282725,69.837967 5.817784,85.020218 2.919763,101.693855 
	C2.795820,102.406944 2.071544,103.015694 1.313465,103.336525 
	C1.000000,69.072701 1.000000,35.145405 1.000000,1.000000 
	C35.020584,1.000000 69.041634,1.000000 103.531342,0.999998 
	C103.413086,1.662214 102.924538,2.747568 102.221672,2.910947 
	C99.640022,3.511042 96.987213,3.805028 93.754753,4.600274 
	C85.098358,8.046478 77.048393,11.106624 68.648407,14.202094 
	C59.850147,20.121901 51.401905,26.006384 42.645096,32.027248 
	C35.520741,40.093304 28.704952,48.022976 21.889164,55.952648 
z"
    />
    <path
      fill="#DD7F81"
      opacity="1.000000"
      stroke="none"
      d="
M361.532410,1.000000 
	C361.999329,55.354736 361.998688,109.709473 361.710785,164.364594 
	C356.563538,160.400864 351.703522,156.136749 346.885864,151.532654 
	C345.707458,149.585434 344.486725,147.978210 343.586945,146.049255 
	C344.271942,143.307831 344.950134,140.888580 344.953461,138.468430 
	C345.016571,92.645683 345.000000,46.822830 345.000000,1.000000 
	C350.354919,1.000000 355.709869,1.000000 361.532410,1.000000 
z"
    />
    <path
      fill="#D47A8F"
      opacity="1.000000"
      stroke="none"
      d="
M305.000000,1.000000 
	C311.687653,1.000000 318.375275,1.000000 325.531464,1.468657 
	C326.002686,45.544880 326.005402,89.152443 325.676483,132.669586 
	C318.551514,129.426575 311.758148,126.273964 304.972351,122.645142 
	C304.986633,81.779289 304.993317,41.389645 305.000000,1.000000 
z"
    />
    <path
      fill="#D97C88"
      opacity="1.000000"
      stroke="none"
      d="
M326.008087,132.760010 
	C326.005402,89.152443 326.002686,45.544880 326.000000,1.468657 
	C332.021149,1.000000 338.042297,1.000000 344.531738,1.000000 
	C345.000000,46.822830 345.016571,92.645683 344.953461,138.468430 
	C344.950134,140.888580 344.271942,143.307831 343.324524,145.878204 
	C337.163452,141.605957 331.585754,137.182983 326.008087,132.760010 
z"
    />
    <path
      fill="#B166C6"
      opacity="1.000000"
      stroke="none"
      d="
M154.039200,165.099686 
	C153.692795,162.487885 153.047211,159.876434 153.044220,157.264252 
	C152.984924,105.488655 153.000000,53.712975 153.000000,1.468657 
	C157.689255,1.000000 162.378510,1.000000 167.533875,1.468657 
	C168.028503,49.376625 168.056992,96.815941 167.929321,144.543854 
	C163.195175,151.588196 158.617188,158.343933 154.039200,165.099686 
z"
    />
    <path
      fill="#B86ABB"
      opacity="1.000000"
      stroke="none"
      d="
M184.000000,1.000000 
	C189.688034,1.000000 195.376083,1.000000 201.532059,1.468657 
	C201.998779,42.234734 201.997543,82.532150 201.672638,123.002945 
	C195.897156,125.703247 190.445374,128.230164 184.898865,130.365387 
	C184.536118,127.346535 184.037781,124.719658 184.034561,122.092171 
	C183.985062,81.728157 184.000015,41.364071 184.000000,1.000000 
z"
    />
    <path
      fill="#B267C4"
      opacity="1.000000"
      stroke="none"
      d="
M178.000000,501.000000 
	C171.645599,501.000000 165.291214,501.000000 158.468414,500.531342 
	C158.000031,462.665375 157.977524,425.268036 158.043228,387.870819 
	C158.048431,384.914215 158.666046,381.958649 159.308289,379.128540 
	C161.997589,381.299957 164.377655,383.345398 166.809113,385.722992 
	C170.580597,390.016418 174.300674,393.977722 178.016296,398.411499 
	C178.007889,432.922638 178.003937,466.961334 178.000000,501.000000 
z"
    />
    <path
      fill="#B468C1"
      opacity="1.000000"
      stroke="none"
      d="
M183.533142,1.000000 
	C184.000015,41.364071 183.985062,81.728157 184.034561,122.092171 
	C184.037781,124.719658 184.536118,127.346535 184.604401,130.553833 
	C178.964935,135.507736 173.525208,139.881485 168.085495,144.255249 
	C168.056992,96.815941 168.028503,49.376625 168.000000,1.468657 
	C173.022095,1.000000 178.044189,1.000000 183.533142,1.000000 
z"
    />
    <path
      fill="#D07796"
      opacity="1.000000"
      stroke="none"
      d="
M304.532043,1.000000 
	C304.993317,41.389645 304.986633,81.779289 304.579956,122.596153 
	C298.096497,121.428978 292.012970,119.834572 285.952850,117.798859 
	C286.317505,112.223572 286.940735,107.090332 286.954285,101.955490 
	C287.043030,68.303856 287.000000,34.651871 287.000000,1.000000 
	C292.688049,1.000000 298.376068,1.000000 304.532043,1.000000 
z"
    />
    <path
      fill="#C06EAF"
      opacity="1.000000"
      stroke="none"
      d="
M218.000000,1.000000 
	C223.688034,1.000000 229.376083,1.000000 235.532059,1.468657 
	C235.999893,39.901115 235.999786,77.864914 235.582947,115.908401 
	C229.451630,116.687920 223.737045,117.387749 218.017639,117.613449 
	C218.008575,78.426216 218.004288,39.713108 218.000000,1.000000 
z"
    />
    <path
      fill="#CC759D"
      opacity="1.000000"
      stroke="none"
      d="
M286.532043,1.000000 
	C287.000000,34.651871 287.043030,68.303856 286.954285,101.955490 
	C286.940735,107.090332 286.317505,112.223572 285.519440,117.726471 
	C279.708618,117.331223 274.354584,116.567062 269.000610,115.376808 
	C269.000427,76.967140 269.000214,38.983570 269.000000,1.000000 
	C274.688049,1.000000 280.376068,1.000000 286.532043,1.000000 
z"
    />
    <path
      fill="#C471A8"
      opacity="1.000000"
      stroke="none"
      d="
M235.999680,115.828712 
	C235.999786,77.864914 235.999893,39.901115 236.000000,1.468657 
	C241.354935,1.000000 246.709854,1.000000 252.532394,1.468657 
	C253.000031,39.902405 253.000076,77.867500 252.535889,115.893326 
	C246.714355,115.912277 241.357010,115.870491 235.999680,115.828712 
z"
    />
    <path
      fill="#B76ABC"
      opacity="1.000000"
      stroke="none"
      d="
M178.468414,501.000000 
	C178.003937,466.961334 178.007889,432.922638 178.307770,398.581665 
	C185.426422,402.765717 192.249146,407.252136 198.983139,412.164307 
	C198.596390,415.209595 198.045532,417.828522 198.039322,420.448730 
	C197.975510,447.299042 198.000092,474.149536 198.000000,501.000000 
	C191.645599,501.000000 185.291214,501.000000 178.468414,501.000000 
z"
    />
    <path
      fill="#BC6CB5"
      opacity="1.000000"
      stroke="none"
      d="
M217.533142,1.000000 
	C218.004288,39.713108 218.008575,78.426216 217.775345,117.904556 
	C212.357330,120.056389 207.176834,121.442978 201.996323,122.829567 
	C201.997543,82.532150 201.998779,42.234734 202.000000,1.468657 
	C207.022095,1.000000 212.044189,1.000000 217.533142,1.000000 
z"
    />
    <path
      fill="#C873A2"
      opacity="1.000000"
      stroke="none"
      d="
M253.000107,115.832588 
	C253.000076,77.867500 253.000031,39.902405 253.000000,1.468657 
	C258.022095,1.000000 263.044189,1.000000 268.533142,1.000000 
	C269.000214,38.983570 269.000427,76.967140 268.537476,115.441193 
	C263.049561,115.898651 258.024841,115.865623 253.000107,115.832588 
z"
    />
    <path
      fill="#DC7E83"
      opacity="1.000000"
      stroke="none"
      d="
M356.000000,501.000000 
	C350.311951,501.000000 344.623932,501.000000 338.467957,500.531342 
	C337.976654,470.632385 337.953308,441.202118 338.142761,411.529968 
	C343.248444,407.233398 348.141327,403.178711 353.286194,398.992035 
	C354.023529,398.563934 354.508911,398.267853 355.027893,398.393250 
	C355.374329,401.944275 355.952057,405.073242 355.958923,408.203430 
	C356.026733,439.135498 355.999969,470.067780 356.000000,501.000000 
z"
    />
    <path
      fill="#BC6CB5"
      opacity="1.000000"
      stroke="none"
      d="
M198.468414,501.000000 
	C198.000092,474.149536 197.975510,447.299042 198.039322,420.448730 
	C198.045532,417.828522 198.596390,415.209595 199.348480,412.247406 
	C204.867599,413.867157 209.932632,415.829590 215.305527,417.904175 
	C216.409119,418.369690 217.204849,418.722992 218.000427,419.540192 
	C218.000168,447.002716 218.000092,474.001343 218.000000,501.000000 
	C211.645599,501.000000 205.291214,501.000000 198.468414,501.000000 
z"
    />
    <path
      fill="#D77B8B"
      opacity="1.000000"
      stroke="none"
      d="
M337.929962,411.771820 
	C337.953308,441.202118 337.976654,470.632385 338.000000,500.531342 
	C331.645599,501.000000 325.291199,501.000000 318.468384,500.531342 
	C317.978058,473.624664 317.956146,447.186676 318.205872,420.541687 
	C322.649384,418.511658 326.821228,416.688599 331.313904,414.750427 
	C333.733124,413.680817 335.831543,412.726318 337.929962,411.771820 
z"
    />
    <path
      fill="#D37992"
      opacity="1.000000"
      stroke="none"
      d="
M317.934235,420.748657 
	C317.956146,447.186676 317.978058,473.624664 318.000000,500.531342 
	C311.312347,501.000000 304.624725,501.000000 297.468536,500.531342 
	C297.000427,475.081848 297.000885,450.101013 297.407532,425.074951 
	C301.205566,424.318176 304.597321,423.606659 308.377502,422.889374 
	C311.822021,422.171967 314.878113,421.460327 317.934235,420.748657 
z"
    />
    <path
      fill="#CE7699"
      opacity="1.000000"
      stroke="none"
      d="
M297.001312,425.120178 
	C297.000885,450.101013 297.000427,475.081848 297.000000,500.531342 
	C290.312347,501.000000 283.624725,501.000000 276.468536,500.531342 
	C275.999207,475.705383 275.998383,451.348053 276.348419,426.962769 
	C277.806183,426.654449 278.913086,426.374146 280.480164,426.105286 
	C286.293976,425.784515 291.647644,425.452332 297.001312,425.120178 
z"
    />
    <path
      fill="#C06FAE"
      opacity="1.000000"
      stroke="none"
      d="
M218.468277,501.000000 
	C218.000092,474.001343 218.000168,447.002716 218.375519,419.591125 
	C224.833237,420.801331 230.915695,422.424469 236.998550,424.513275 
	C236.999298,450.319305 236.999649,475.659637 237.000000,501.000000 
	C230.978851,501.000000 224.957703,501.000000 218.468277,501.000000 
z"
    />
    <path
      fill="#C973A1"
      opacity="1.000000"
      stroke="none"
      d="
M275.997559,426.990784 
	C275.998383,451.348053 275.999207,475.705383 276.000000,500.531342 
	C269.645599,501.000000 263.291199,501.000000 256.468384,500.531342 
	C256.000885,475.417084 256.001770,450.771515 256.457855,426.099365 
	C263.274567,426.378784 269.636078,426.684784 275.997559,426.990784 
z"
    />
    <path
      fill="#C471A8"
      opacity="1.000000"
      stroke="none"
      d="
M256.002625,426.125916 
	C256.001770,450.771515 256.000885,475.417084 256.000000,500.531342 
	C249.978851,501.000000 243.957703,501.000000 237.468277,501.000000 
	C236.999649,475.659637 236.999298,450.319305 237.421890,424.496399 
	C241.220764,424.389587 244.596664,424.765381 248.303040,425.354462 
	C251.089890,425.753784 253.546249,425.939850 256.002625,426.125916 
z"
    />
    <path
      fill="#AE65CA"
      opacity="1.000000"
      stroke="none"
      d="
M158.999039,379.002594 
	C158.666046,381.958649 158.048431,384.914215 158.043228,387.870819 
	C157.977524,425.268036 158.000031,462.665375 158.000000,500.531342 
	C154.638718,501.000000 151.277420,501.000000 147.458069,501.000000 
	C147.000015,459.603943 146.978836,418.207825 147.059830,376.811920 
	C147.064072,374.639740 147.935715,372.469238 149.056610,370.278931 
	C152.806137,373.174133 155.902588,376.088348 158.999039,379.002594 
z"
    />
    <path
      fill="#DF807E"
      opacity="1.000000"
      stroke="none"
      d="
M356.458069,501.000000 
	C355.999969,470.067780 356.026733,439.135498 355.958923,408.203430 
	C355.952057,405.073242 355.374329,401.944275 355.190674,398.103821 
	C358.809631,392.578796 362.299500,387.764709 365.789337,382.950623 
	C366.192871,385.184753 366.943756,387.418243 366.948181,389.653198 
	C367.021088,426.456268 366.999969,463.259491 367.000000,500.531342 
	C363.638702,501.000000 360.277435,501.000000 356.458069,501.000000 
z"
    />
    <path
      fill="#9F5CE2"
      opacity="1.000000"
      stroke="none"
      d="
M68.998428,14.166770 
	C77.048393,11.106624 85.098358,8.046478 93.551941,4.937335 
	C94.303520,7.167808 94.955391,9.447188 94.956078,11.726764 
	C95.003708,170.945084 95.002892,330.163422 94.963326,489.381744 
	C94.962753,491.681946 94.423553,493.982056 94.047165,496.698486 
	C85.382965,493.974243 76.807343,490.833740 68.300674,487.026978 
	C68.579697,484.061188 68.973251,481.761688 68.973679,479.462097 
	C69.002106,324.363647 68.998901,169.265213 68.998428,14.166770 
z"
    />
    <path
      fill="#EA866D"
      opacity="1.000000"
      stroke="none"
      d="
M422.000946,492.832642 
	C415.267883,494.872803 408.534821,496.912994 401.401398,498.893127 
	C400.667358,494.701965 400.043213,490.570953 400.041931,486.439758 
	C399.992401,328.579987 399.995544,170.720215 400.034027,12.860455 
	C400.034760,9.893732 400.544464,6.927134 400.908722,3.563448 
	C408.232178,5.192742 415.463654,7.219067 422.708282,9.960535 
	C422.480988,13.310048 422.030548,15.944372 422.029999,18.578789 
	C421.996826,176.663406 422.000641,334.748016 422.000946,492.832642 
z"
    />
    <path
      fill="#9356F4"
      opacity="1.000000"
      stroke="none"
      d="
M22.127363,56.296783 
	C28.704952,48.022976 35.520741,40.093304 42.807961,32.419022 
	C43.519505,34.642273 43.969376,36.610085 43.969830,38.578003 
	C44.002693,180.484940 44.004204,322.391876 43.953850,464.298798 
	C43.953171,466.215027 43.260365,468.130981 42.890606,470.047058 
	C36.004601,462.282013 29.118589,454.516968 22.097742,446.436615 
	C22.308493,443.840729 22.955608,441.560272 22.956491,439.279572 
	C23.005190,313.532257 23.002415,187.784912 22.973475,62.037567 
	C22.973061,60.238636 22.576977,58.439796 22.127363,56.296783 
z"
    />
    <path
      fill="#ECB3B2"
      opacity="1.000000"
      stroke="none"
      d="
M365.892395,382.598236 
	C362.299500,387.764709 358.809631,392.578796 355.157043,397.682343 
	C354.508911,398.267853 354.023529,398.563934 353.277344,398.541260 
	C353.301514,390.171387 353.889465,382.115723 353.768463,374.070679 
	C353.682831,368.376648 352.637970,362.697021 352.064270,356.627319 
	C357.708435,337.923767 360.219360,319.303131 355.905151,300.408417 
	C354.077026,292.401855 350.800415,284.726074 348.533813,276.909637 
	C352.912811,280.620148 356.940155,284.320923 360.967499,288.021729 
	C367.198273,297.396240 372.290741,307.349640 373.918518,318.573273 
	C377.094025,340.468719 376.080353,361.949677 365.892395,382.598236 
z"
    />
    <path
      fill="#DD7F82"
      opacity="1.000000"
      stroke="none"
      d="
M361.193359,287.671997 
	C356.940155,284.320923 352.912811,280.620148 348.294250,276.704407 
	C346.801788,275.326752 345.900574,274.164093 345.159424,272.616516 
	C345.544830,270.767548 345.963715,269.303833 345.966492,267.839355 
	C346.008270,245.946228 346.020172,224.052917 345.947723,202.159927 
	C345.941864,200.392014 345.268036,198.626297 345.004242,196.534683 
	C350.354645,189.129425 355.605408,182.049026 360.856201,174.968628 
	C361.236572,176.542908 361.946014,178.116547 361.948914,179.691513 
	C362.012329,214.267014 362.003693,248.842682 361.972992,283.418274 
	C361.971832,284.719788 361.611969,286.020966 361.193359,287.671997 
z"
    />
    <path
      fill="#E9B1B7"
      opacity="1.000000"
      stroke="none"
      d="
M361.011047,174.665619 
	C355.605408,182.049026 350.354645,189.129425 344.740448,196.743820 
	C342.774139,199.381760 341.082245,201.424683 339.589386,203.603958 
	C337.373505,206.838730 334.665405,207.863556 330.909515,205.639557 
	C325.472931,201.249588 320.219574,197.136307 314.781921,192.815094 
	C314.046997,192.112671 313.496368,191.618179 313.027161,190.811981 
	C313.451721,189.966400 313.794891,189.432495 314.538879,188.922424 
	C318.333954,189.572586 320.403931,187.997849 322.356964,185.273224 
	C330.399139,174.053680 338.661896,162.992233 346.843536,151.872650 
	C351.703522,156.136749 356.563538,160.400864 361.709808,164.833344 
	C366.447571,168.541367 362.880768,171.369873 361.011047,174.665619 
z"
    />
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M346.885864,151.532654 
	C338.661896,162.992233 330.399139,174.053680 322.356964,185.273224 
	C320.403931,187.997849 318.333954,189.572586 314.359009,188.641205 
	C310.424469,185.353287 307.328125,181.999863 303.668518,179.457016 
	C292.622986,171.782059 280.809814,165.834671 266.579315,165.069733 
	C255.105820,165.063156 244.052856,165.111176 232.641006,165.264160 
	C213.147491,169.341614 202.283325,180.698547 203.915741,198.616135 
	C204.823471,208.579590 212.264069,218.728119 222.388962,222.038162 
	C227.775513,224.127304 232.890396,225.980545 238.402924,227.903015 
	C244.176575,229.046509 249.552582,230.120789 255.201996,231.467499 
	C261.631042,232.877670 267.786652,234.015396 274.200226,235.444611 
	C281.280670,237.224152 288.103149,238.712219 295.171356,240.465927 
	C301.269104,242.852814 307.121155,244.974045 313.163086,247.384964 
	C318.540955,250.521606 323.728943,253.368576 329.093445,256.494659 
	C334.513062,262.182983 339.756226,267.592194 344.999359,273.001404 
	C345.900574,274.164093 346.801788,275.326752 347.942566,276.694702 
	C350.800415,284.726074 354.077026,292.401855 355.905151,300.408417 
	C360.219360,319.303131 357.708435,337.923767 351.839966,356.891937 
	C346.597015,369.300140 339.279022,379.349731 329.285339,387.811707 
	C321.629120,392.222046 314.267181,396.481171 306.525574,400.812500 
	C297.428375,403.250153 288.710938,405.615601 279.545776,407.984924 
	C268.731689,408.368134 258.365265,408.747467 247.543427,409.090576 
	C236.404083,408.002625 225.720154,406.950928 214.793579,405.631958 
	C205.046280,402.091248 195.385971,399.200104 186.089218,395.415863 
	C179.394409,392.690765 173.184128,388.775330 166.757721,385.390869 
	C164.377655,383.345398 161.997589,381.299957 159.308289,379.128540 
	C155.902588,376.088348 152.806137,373.174133 149.415710,370.112000 
	C146.018234,365.406311 142.914749,360.848572 139.822342,356.307098 
	C144.728287,350.188171 149.327713,344.451508 154.327271,338.254150 
	C158.832870,332.567261 162.938385,327.341156 167.361694,321.944702 
	C172.731339,317.447998 174.939499,317.677643 178.906738,322.853790 
	C179.693848,323.880798 180.668869,324.763733 181.668488,326.023926 
	C188.519821,332.509003 195.259872,338.683289 202.304443,345.027649 
	C209.388489,348.527924 216.168030,351.858154 223.190475,355.466156 
	C229.955994,357.094391 236.478577,358.444885 243.431427,359.859833 
	C250.574051,359.873657 257.286407,359.822998 264.409668,359.836975 
	C270.914124,357.952240 277.007690,356.002899 283.447083,354.002106 
	C300.538422,345.172485 302.860474,324.454834 297.948059,310.664734 
	C295.565216,303.177551 290.256226,298.464203 283.842682,295.589722 
	C276.171692,292.151703 267.971771,289.893799 259.606384,287.066315 
	C248.486694,284.663940 237.761612,282.320007 226.747574,279.726135 
	C220.419006,277.765930 214.215668,276.482727 208.378326,274.244873 
	C199.729950,270.929321 191.341156,266.936584 182.602982,262.997009 
	C177.262070,258.455994 172.160034,254.150131 166.919830,249.539307 
	C162.578293,242.793396 158.374924,236.352463 154.171555,229.911514 
	C152.426193,222.094345 150.530045,214.307144 148.969315,206.453278 
	C146.350998,193.277573 149.045425,180.639099 153.280624,168.202820 
	C153.544586,167.427704 153.774689,166.641052 154.029877,165.479736 
	C158.617188,158.343933 163.195175,151.588196 167.929321,144.543854 
	C173.525208,139.881485 178.964935,135.507736 184.699127,130.945526 
	C190.445374,128.230164 195.897156,125.703247 201.672638,123.002945 
	C207.176834,121.442978 212.357330,120.056389 217.780151,118.378693 
	C223.737045,117.387749 229.451630,116.687920 235.582947,115.908401 
	C241.357010,115.870491 246.714355,115.912277 252.535889,115.893326 
	C258.024841,115.865623 263.049561,115.898651 268.537415,115.867294 
	C274.354584,116.567062 279.708618,117.331223 285.496063,118.167770 
	C292.012970,119.834572 298.096497,121.428978 304.572388,123.072365 
	C311.758148,126.273964 318.551514,129.426575 325.676483,132.669586 
	C331.585754,137.182983 337.163452,141.605957 343.003540,146.199966 
	C344.486725,147.978210 345.707458,149.585434 346.885864,151.532654 
z"
    />
    <path
      fill="#B166C7"
      opacity="1.000000"
      stroke="none"
      d="
M167.043884,322.115021 
	C162.938385,327.341156 158.832870,332.567261 154.417038,337.920837 
	C153.738113,336.300446 153.051514,334.553284 153.048050,332.804749 
	C152.983688,300.362640 152.990997,267.920380 153.029358,235.478180 
	C153.031281,233.851089 153.450745,232.224487 153.923676,230.254578 
	C158.374924,236.352463 162.578293,242.793396 166.897797,249.976440 
	C166.675949,265.601685 166.146149,280.483887 166.072021,295.368347 
	C166.027634,304.282074 166.695923,313.199341 167.043884,322.115021 
z"
    />
    <path
      fill="#F98E57"
      opacity="1.000000"
      stroke="none"
      d="
M483.645660,439.617828 
	C477.795471,447.917358 471.756866,455.853027 465.174438,463.574799 
	C464.420532,461.727997 464.026947,460.095093 464.026611,458.462158 
	C463.998260,320.208496 463.995880,181.954849 464.046326,43.701202 
	C464.046997,41.784752 464.739502,39.868553 465.109100,37.952236 
	C471.408112,46.044624 477.707123,54.137005 484.058228,62.591568 
	C483.740295,64.868889 483.047211,66.783897 483.046417,68.699203 
	C482.995117,190.570938 482.998352,312.442688 483.019073,434.314453 
	C483.019348,435.960999 483.304871,437.607452 483.645660,439.617828 
z"
    />
    <path
      fill="#EF8865"
      opacity="1.000000"
      stroke="none"
      d="
M422.359955,492.789703 
	C422.000641,334.748016 421.996826,176.663406 422.029999,18.578789 
	C422.030548,15.944372 422.480988,13.310048 422.884796,10.284178 
	C429.953979,13.347018 436.859802,16.801363 443.743683,20.965515 
	C443.481323,23.643011 443.030884,25.610653 443.030457,27.578394 
	C442.997711,176.476654 442.995789,325.374939 443.043732,474.273193 
	C443.044464,476.552826 443.696472,478.832275 444.044556,481.111816 
	C438.240692,484.404205 432.447998,487.716522 426.624115,490.973022 
	C425.380585,491.668365 424.023651,492.160858 422.359955,492.789703 
z"
    />
    <path
      fill="#F48B5E"
      opacity="1.000000"
      stroke="none"
      d="
M444.400635,481.057190 
	C443.696472,478.832275 443.044464,476.552826 443.043732,474.273193 
	C442.995789,325.374939 442.997711,176.476654 443.030457,27.578394 
	C443.030884,25.610653 443.481323,23.643011 443.885010,21.283810 
	C450.978668,26.372381 457.908997,31.852470 464.974213,37.642395 
	C464.739502,39.868553 464.046997,41.784752 464.046326,43.701202 
	C463.995880,181.954849 463.998260,320.208496 464.026611,458.462158 
	C464.026947,460.095093 464.420532,461.727997 464.867706,463.705994 
	C458.322144,469.701569 451.539459,475.352081 444.400635,481.057190 
z"
    />
    <path
      fill="#9959EC"
      opacity="1.000000"
      stroke="none"
      d="
M43.032288,470.365112 
	C43.260365,468.130981 43.953171,466.215027 43.953850,464.298798 
	C44.004204,322.391876 44.002693,180.484940 43.969830,38.578003 
	C43.969376,36.610085 43.519505,34.642273 43.116528,32.282639 
	C51.401905,26.006384 59.850147,20.121901 68.648407,14.202094 
	C68.998901,169.265213 69.002106,324.363647 68.973679,479.462097 
	C68.973251,481.761688 68.579697,484.061188 68.132629,486.705902 
	C61.284649,482.891846 54.648190,478.772217 48.079720,474.546875 
	C46.337109,473.425873 44.803535,471.979889 43.032288,470.365112 
z"
    />
    <path
      fill="#D5A6D6"
      opacity="1.000000"
      stroke="none"
      d="
M166.809113,385.722992 
	C173.184128,388.775330 179.394409,392.690765 186.089218,395.415863 
	C195.385971,399.200104 205.046280,402.091248 214.784637,406.099579 
	C215.011444,410.486938 215.004562,414.139496 214.997681,417.792023 
	C209.932632,415.829590 204.867599,413.867157 199.437195,411.821655 
	C192.249146,407.252136 185.426422,402.765717 178.312225,398.109192 
	C174.300674,393.977722 170.580597,390.016418 166.809113,385.722992 
z"
    />
    <path
      fill="#EAB1B6"
      opacity="1.000000"
      stroke="none"
      d="
M329.579651,387.660522 
	C339.279022,379.349731 346.597015,369.300140 351.796936,357.275574 
	C352.637970,362.697021 353.682831,368.376648 353.768463,374.070679 
	C353.889465,382.115723 353.301514,390.171387 353.025391,398.673279 
	C348.141327,403.178711 343.248444,407.233398 338.142761,411.529968 
	C335.831543,412.726318 333.733124,413.680817 331.315918,414.295166 
	C330.997925,407.209686 331.106079,400.461639 330.934326,393.720703 
	C330.882599,391.690460 330.052948,389.680023 329.579651,387.660522 
z"
    />
    <path
      fill="#DAA9CD"
      opacity="1.000000"
      stroke="none"
      d="
M215.305527,417.904175 
	C215.004562,414.139496 215.011444,410.486938 215.027267,406.366821 
	C225.720154,406.950928 236.404083,408.002625 247.543762,409.546906 
	C247.990509,415.073364 247.981552,420.107269 247.972595,425.141174 
	C244.596664,424.765381 241.220764,424.389587 237.421509,424.030701 
	C230.915695,422.424469 224.833237,420.801331 218.375687,419.127258 
	C217.204849,418.722992 216.409119,418.369690 215.305527,417.904175 
z"
    />
    <path
      fill="#E7B0BB"
      opacity="1.000000"
      stroke="none"
      d="
M329.285339,387.811707 
	C330.052948,389.680023 330.882599,391.690460 330.934326,393.720703 
	C331.106079,400.461639 330.997925,407.209686 330.995087,414.410278 
	C326.821228,416.688599 322.649384,418.511658 318.205872,420.541687 
	C314.878113,421.460327 311.822021,422.171967 308.291504,422.491089 
	C307.513184,414.979187 307.209229,407.859741 306.905273,400.740295 
	C314.267181,396.481171 321.629120,392.222046 329.285339,387.811707 
z"
    />
    <path
      fill="#E3AEC1"
      opacity="1.000000"
      stroke="none"
      d="
M306.525574,400.812500 
	C307.209229,407.859741 307.513184,414.979187 307.903107,422.496887 
	C304.597321,423.606659 301.205566,424.318176 297.407532,425.074951 
	C291.647644,425.452332 286.293976,425.784515 280.475006,425.627930 
	C280.004272,419.419769 279.998871,413.700409 279.993469,407.981079 
	C288.710938,405.615601 297.428375,403.250153 306.525574,400.812500 
z"
    />
    <path
      fill="#DFABC7"
      opacity="1.000000"
      stroke="none"
      d="
M279.545776,407.984924 
	C279.998871,413.700409 280.004272,419.419769 280.014832,425.616516 
	C278.913086,426.374146 277.806183,426.654449 276.348419,426.962769 
	C269.636078,426.684784 263.274567,426.378784 256.457855,426.099365 
	C253.546249,425.939850 251.089890,425.753784 248.303055,425.354431 
	C247.981552,420.107269 247.990509,415.073364 247.999161,409.583130 
	C258.365265,408.747467 268.731689,408.368134 279.545776,407.984924 
z"
    />
    <path
      fill="#DA7D87"
      opacity="1.000000"
      stroke="none"
      d="
M345.159424,272.616516 
	C339.756226,267.592194 334.513062,262.182983 329.187317,256.071259 
	C329.403015,252.912308 329.940582,250.457687 329.959076,247.999161 
	C330.049042,236.050339 329.948181,224.100037 330.049255,212.151398 
	C330.066864,210.070221 330.728516,207.994492 331.092743,205.916245 
	C334.665405,207.863556 337.373505,206.838730 339.589386,203.603958 
	C341.082245,201.424683 342.774139,199.381760 344.640808,197.068695 
	C345.268036,198.626297 345.941864,200.392014 345.947723,202.159927 
	C346.020172,224.052917 346.008270,245.946228 345.966492,267.839355 
	C345.963715,269.303833 345.544830,270.767548 345.159424,272.616516 
z"
    />
    <path
      fill="#D27993"
      opacity="1.000000"
      stroke="none"
      d="
M312.973145,247.095276 
	C307.121155,244.974045 301.269104,242.852814 295.187653,239.983749 
	C294.979736,221.107132 295.001221,202.978363 295.414368,184.884369 
	C301.519287,186.987350 307.232513,189.055511 312.945770,191.123688 
	C313.496368,191.618179 314.046997,192.112671 314.479492,193.053192 
	C313.907715,195.541321 313.075745,197.580597 313.060455,199.625961 
	C312.942413,215.448410 312.982239,231.272018 312.973145,247.095276 
z"
    />
    <path
      fill="#D67B8D"
      opacity="1.000000"
      stroke="none"
      d="
M313.163055,247.384964 
	C312.982239,231.272018 312.942413,215.448410 313.060455,199.625961 
	C313.075745,197.580597 313.907715,195.541321 314.663818,193.261139 
	C320.219574,197.136307 325.472931,201.249588 330.909515,205.639557 
	C330.728516,207.994492 330.066864,210.070221 330.049255,212.151398 
	C329.948181,224.100037 330.049042,236.050339 329.959076,247.999161 
	C329.940582,250.457687 329.403015,252.912308 329.010803,255.792145 
	C323.728943,253.368576 318.540955,250.521606 313.163055,247.384964 
z"
    />
    <path
      fill="#E2ADC3"
      opacity="1.000000"
      stroke="none"
      d="
M313.027161,190.811981 
	C307.232513,189.055511 301.519287,186.987350 295.095825,184.686996 
	C287.924957,183.675735 281.464294,182.896667 274.559937,182.082230 
	C271.744141,181.989670 269.372070,181.932465 267.000000,181.419830 
	C266.999939,175.684372 266.999908,170.404343 266.999847,165.124329 
	C280.809814,165.834671 292.622986,171.782059 303.668518,179.457016 
	C307.328125,181.999863 310.424469,185.353287 313.958191,188.617355 
	C313.794891,189.432495 313.451721,189.966400 313.027161,190.811981 
z"
    />
    <path
      fill="#C672A5"
      opacity="1.000000"
      stroke="none"
      d="
M263.998749,359.772369 
	C257.286407,359.822998 250.574051,359.873657 243.431152,359.404907 
	C243.000610,338.256104 243.000610,317.626678 243.403152,297.015411 
	C249.193787,298.068481 254.581879,299.103424 260.232666,300.319336 
	C261.668610,300.976868 262.841888,301.453461 264.011841,302.403137 
	C264.005280,321.841553 264.002014,340.806976 263.998749,359.772369 
z"
    />
    <path
      fill="#BD6DB4"
      opacity="1.000000"
      stroke="none"
      d="
M222.947556,355.188385 
	C216.168030,351.858154 209.388489,348.527924 202.340057,344.617798 
	C202.380737,341.083832 202.943649,338.131073 202.958282,335.175568 
	C203.046173,317.433899 203.008102,299.691620 203.354584,282.035278 
	C210.129425,285.100769 216.561890,288.080536 222.995621,291.525177 
	C222.980453,313.056152 222.964005,334.122284 222.947556,355.188385 
z"
    />
    <path
      fill="#C16FAD"
      opacity="1.000000"
      stroke="none"
      d="
M223.190475,355.466156 
	C222.964005,334.122284 222.980453,313.056152 223.330414,291.517944 
	C224.110291,291.052246 224.556656,291.058685 225.316574,291.240997 
	C231.420303,293.277008 237.210449,295.137146 243.000610,296.997284 
	C243.000610,317.626678 243.000610,338.256104 243.000885,359.340424 
	C236.478577,358.444885 229.955994,357.094391 223.190475,355.466156 
z"
    />
    <path
      fill="#B86ABB"
      opacity="1.000000"
      stroke="none"
      d="
M203.012222,281.949524 
	C203.008102,299.691620 203.046173,317.433899 202.958282,335.175568 
	C202.943649,338.131073 202.380737,341.083832 202.035553,344.447723 
	C195.259872,338.683289 188.519821,332.509003 181.889648,325.593933 
	C182.332901,322.576965 182.941696,320.302338 182.954926,318.024292 
	C183.041733,303.070862 182.954926,288.116425 183.052292,273.163147 
	C183.065781,271.092682 183.782257,269.026764 184.444244,267.131897 
	C190.814178,272.186523 196.913208,277.068024 203.012222,281.949524 
z"
    />
    <path
      fill="#CD769A"
      opacity="1.000000"
      stroke="none"
      d="
M275.003662,182.117599 
	C281.464294,182.896667 287.924957,183.675735 294.704163,184.652191 
	C295.001221,202.978363 294.979736,221.107132 294.941925,239.718094 
	C288.103149,238.712219 281.280670,237.224152 274.291077,235.025421 
	C274.415894,231.856705 274.947296,229.399933 274.960480,226.940384 
	C275.040436,211.999817 275.002319,197.058594 275.003662,182.117599 
z"
    />
    <path
      fill="#B468C2"
      opacity="1.000000"
      stroke="none"
      d="
M184.173309,266.958740 
	C183.782257,269.026764 183.065781,271.092682 183.052292,273.163147 
	C182.954926,288.116425 183.041733,303.070862 182.954926,318.024292 
	C182.941696,320.302338 182.332901,322.576965 181.778397,325.283142 
	C180.668869,324.763733 179.693848,323.880798 178.906738,322.853790 
	C174.939499,317.677643 172.731339,317.447998 167.361694,321.944702 
	C166.695923,313.199341 166.027634,304.282074 166.072021,295.368347 
	C166.146149,280.483887 166.675949,265.601685 167.035950,250.281403 
	C172.160034,254.150131 177.262070,258.455994 182.629150,263.326172 
	C183.320557,264.913239 183.746933,265.936005 184.173309,266.958740 
z"
    />
    <path
      fill="#D9A8D1"
      opacity="1.000000"
      stroke="none"
      d="
M222.117294,221.802277 
	C212.264069,218.728119 204.823471,208.579590 203.915741,198.616135 
	C202.283325,180.698547 213.147491,169.341614 232.641083,165.719757 
	C233.331009,173.702942 233.662003,181.335495 233.763763,189.208527 
	C222.569046,197.706818 219.217194,208.651413 222.117294,221.802277 
z"
    />
    <path
      fill="#C973A1"
      opacity="1.000000"
      stroke="none"
      d="
M274.559937,182.082230 
	C275.002319,197.058594 275.040436,211.999817 274.960480,226.940384 
	C274.947296,229.399933 274.415894,231.856705 274.033142,234.733948 
	C267.786652,234.015396 261.631042,232.877670 255.217712,230.985641 
	C254.973358,214.219833 254.986679,198.208298 255.458099,182.140411 
	C259.610809,182.014435 263.305420,181.944855 267.000000,181.875275 
	C269.372070,181.932465 271.744141,181.989670 274.559937,182.082230 
z"
    />
    <path
      fill="#CB759E"
      opacity="1.000000"
      stroke="none"
      d="
M264.409668,359.836975 
	C264.002014,340.806976 264.005280,321.841553 264.420349,302.419006 
	C270.554077,303.325806 276.276001,304.689758 281.998413,306.519012 
	C281.999329,320.597229 281.953430,334.210449 282.049286,347.822693 
	C282.063904,349.902008 282.734192,351.976746 283.101257,354.053589 
	C277.007690,356.002899 270.914124,357.952240 264.409668,359.836975 
z"
    />
    <path
      fill="#C571A8"
      opacity="1.000000"
      stroke="none"
      d="
M255.000000,182.196777 
	C254.986679,198.208298 254.973358,214.219833 254.944305,230.713211 
	C249.552582,230.120789 244.176575,229.046509 238.493561,227.506409 
	C238.125641,213.735962 238.064728,200.431366 238.320267,186.937805 
	C244.091141,185.231476 249.545563,183.714127 255.000000,182.196777 
z"
    />
    <path
      fill="#CF7798"
      opacity="1.000000"
      stroke="none"
      d="
M283.447083,354.002136 
	C282.734192,351.976746 282.063904,349.902008 282.049286,347.822693 
	C281.953430,334.210449 281.999329,320.597229 282.386108,306.526428 
	C287.833710,307.720825 292.894135,309.373077 297.954529,311.025330 
	C302.860474,324.454834 300.538422,345.172485 283.447083,354.002136 
z"
    />
    <path
      fill="#DDAACA"
      opacity="1.000000"
      stroke="none"
      d="
M255.458099,182.140396 
	C249.545563,183.714127 244.091141,185.231476 238.025970,187.047241 
	C236.274460,187.886429 235.133713,188.427231 233.992981,188.968018 
	C233.662003,181.335495 233.331009,173.702942 232.999969,165.614807 
	C244.052856,165.111176 255.105820,165.063156 266.579315,165.069733 
	C266.999908,170.404343 266.999939,175.684372 267.000000,181.419830 
	C263.305420,181.944855 259.610809,182.014435 255.458099,182.140396 
z"
    />
    <path
      fill="#C16FAD"
      opacity="1.000000"
      stroke="none"
      d="
M233.763763,189.208527 
	C235.133713,188.427231 236.274460,187.886429 237.709503,187.236191 
	C238.064728,200.431366 238.125641,213.735962 238.095917,227.437195 
	C232.890396,225.980545 227.775513,224.127304 222.388962,222.038162 
	C219.217194,208.651413 222.569046,197.706818 233.763763,189.208527 
z"
    />
    <path
      fill="#DCAACB"
      opacity="1.000000"
      stroke="none"
      d="
M243.403152,297.015411 
	C237.210449,295.137146 231.420303,293.277008 225.315704,290.787781 
	C225.679688,286.764496 226.358093,283.370270 227.036514,279.976074 
	C237.761612,282.320007 248.486694,284.663940 259.606079,287.519318 
	C259.990204,292.066620 259.980072,296.102509 259.969971,300.138367 
	C254.581879,299.103424 249.193787,298.068481 243.403152,297.015411 
z"
    />
    <path
      fill="#E0ACC4"
      opacity="1.000000"
      stroke="none"
      d="
M260.232666,300.319305 
	C259.980072,296.102509 259.990204,292.066620 260.000641,287.577759 
	C267.971771,289.893799 276.171692,292.151703 283.842682,295.589722 
	C290.256226,298.464203 295.565216,303.177551 297.948059,310.664734 
	C292.894135,309.373077 287.833710,307.720825 282.385620,306.061157 
	C276.276001,304.689758 270.554077,303.325806 264.423645,301.945984 
	C262.841888,301.453461 261.668610,300.976868 260.232666,300.319305 
z"
    />
    <path
      fill="#D7A7D3"
      opacity="1.000000"
      stroke="none"
      d="
M226.747574,279.726135 
	C226.358093,283.370270 225.679688,286.764496 225.002136,290.611908 
	C224.556656,291.058685 224.110291,291.052246 223.329147,291.053040 
	C216.561890,288.080536 210.129425,285.100769 203.354584,282.035278 
	C196.913208,277.068024 190.814178,272.186523 184.444244,267.131897 
	C183.746933,265.936005 183.320557,264.913239 182.868027,263.561340 
	C191.341156,266.936584 199.729950,270.929321 208.378326,274.244873 
	C214.215668,276.482727 220.419006,277.765930 226.747574,279.726135 
z"
    />
  </svg>
);

export default Logo;
